import Title from "./Title/Title";
import HoverProject from "./HoverProject";

import { useRef, useEffect } from "react";
import { useInView, motion, transform } from "framer-motion";

const Ourproject = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  return (
    <div
      id='project'
      className=' h-full w-full py-16 gap-9 flex flex-col items-center justify-center px-[10vw] md:bg-white overflow-hidden relative '>
      {/* bg-gradient-to-br from-red-200 to-purple-200 */}
      <Title />
      <HoverProject />
      {/* <div className='mix-blend-color-multiply'></div> */}
    </div>
  );
};

export default Ourproject;
