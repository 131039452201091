import Clientimages from "../../assets/Clientimage";
import Title from "./Title";

const Ourclients = () => {
  return (
    <div id="client" className=" h-full w-full flex flex-col items-center justify-center py-16 bg-none gap-10 md:gap-0">
      <Title/>
      {/* grid grid-cols-2 sm:grid-cols-3 col-span-1 justify-items-center justify-self-center */}
      <div className=" flex flex-row justify-center items-center  flex-wrap gap-[16px] w-[90vw]">
        {Clientimages.map((Clientimage, index) => (
          <div
            className="border-[10px]/ border-[#0683e4]"
            key={index}
          >
            <img
              className="w-auto max-w-[120px] lg:max-w-[200px] lg:w-full h-auto md:h-44 lg:h-72 object-contain grayscale"
              src={Clientimage}
              alt=""
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ourclients;
