import { motion } from "framer-motion";

import Kawa from "../../assets/PROJECT/asset motion/kawa_gif.webp";
import Cheosnun from "../../assets/PROJECT/asset motion/cheosnun2.gif";
import Albens from "../../assets/PROJECT/asset motion/albens.jpg";
import Kinghorse from "../../assets/PROJECT/asset motion/KinghorseHover.jpg";

import Kawalogo from "../../assets/PROJECT/Logo/KawalogoNOBG.png";
import Cheosnunlogo from "../../assets/PROJECT/Logo/Cheonsnun.png";
import Albenslogo from "../../assets/PROJECT/Logo/Albenslogo.webp";
import Kinglogo from "../../assets/PROJECT/Logo/Kinghorselogo.webp";

import HoverBox from "./HoverBox";

const HoverProject = () => {
  return (
    <div className=' w-[90vw] md:w-full  h-screen md:h-[70vh] grid grid-rows-8 grid-cols-2 md:grid-cols-4 md:grid-rows-1 gap-4 md:gap-10  newake'>
      <div className='relative kawakawa border-[#ffffff] border-[5px] md:bg-center md:bg- md:bg-cover col-span-2 md:col-span-1 row-span-3 md:row-span-1  lg:row-span-1 bg-cover  bg-[#d93939]/ bg-black bg-no-repeat bg-center rounded-sm  dropshadow'>
        <div className='absolute w-full h-full bg-black opacity-50'></div>
        <div className='absolute flex justify-center items-center w-full h-full'>
          <img className='w-40 h-auto whitedropshadow' src={Kawalogo} alt='' />
        </div>
        <HoverBox Name='Kawa Kawa' bgvideo={Kawa} />
      </div>
      <div className='relative cheonsun border-[#ffffff] border-[5px] md:bg-center col-span-2 md:col-span-1 row-span-3 md:row-span-1  lg:row-span-1 bg-cover  bg-[#8fc6ff] bg-no-repeat bg-center rounded-sm  dropshadow'>
        <div className='absolute w-full h-full bg-black opacity-50'></div>
        <div className='absolute flex justify-center items-center w-full h-full'>
          <img className='w-40 h-auto whitedropshadow' src={Cheosnunlogo} alt='' />
        </div>
        <HoverBox Name='Cheosnun' bgvideo={Cheosnun} />
      </div>
      <div className='relative albens border-[#ffffff] border-[5px] col-span-1 md:row-span-1 row-span-2  bg-cover  bg-center rounded-sm dropshadow'>
        <div className='absolute w-full h-full bg-black opacity-50'></div>
        <div className='absolute flex justify-center items-center w-full h-full'>
          <img className='w-40 h-auto whitedropshadow' src={Albenslogo} alt='' />
        </div>
        <HoverBox Name='Albens' bgvideo={Albens} />
      </div>
      <div className='relative  border-[#ffffff] border-[5px] col-span-1 md:row-span-1 row-span-2  bg-cover bg-center rounded-sm dropshadow kinghorse'>
        <div className='absolute w-full h-full bg-black opacity-50'></div>
        <div className='absolute flex justify-center items-center w-full h-full'>
          <img className='w-1/2 sm:w-36 h-auto whitedropshadow' src={Kinglogo} alt='' />
        </div>
        <HoverBox Name='Kinghorse' bgvideo={Kinghorse} />
      </div>
    </div>
  );
};

export default HoverProject;
