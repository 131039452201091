import { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { motion, transform } from "framer-motion";

import LogoNoText from "../../assets/Pictures/LOGO/logo createams without text.png";
import Heropicture from "../../assets/Pictures/HOMEPAGE/newhero.png";

import HomeDecor from "./HomeDecor";

const Home = () => {
  return (
    <div className='relative min-h-screen w-full px-4 md:pr-0 md:grid md:grid-cols-12 md:grid-rows-1  border-black overflow-hidden flex justify-center items-center text-[#2C2C2C] md:pl-0 md:place-content-center'>
      {/* <video
        autoPlay
        loop
        muted
        className='videoscale absolute blur-sm -z-50 opacity-40 object-cover h-full w-full'>
        <source src={Videofooter} type='video/mp4' />
      </video> */}
      <div className='hidden md:block md:col-span-1 md:row'></div>

      <div className=' h-[90%] w-full flex flex-col items-center justify-center md:gap md:items-start md:justify-center md:col-span-7 lg:col-span-6'>
        <div className='flex flex-col md:flex-row md:justify-center items-center'>
          <div className='md:py-4 md:pr-4'>
            <img
              className='w-5 md:w-7 lg:w-10 h-auto'
              src={LogoNoText}
              alt=''
            />
          </div>
          <div className='pt-4 md:pt-0'>
            <h2 className='newake text-2xl lg:text-4xl md:pt-2 tracking-wider '>
              <span className='text-[#cd2127]'>C</span>
              <span className='text-[#f07b2a]'>R</span>
              <span className='text-[#f07b2a]'>E</span>
              <span className='text-[#5bbb47]'>A</span>
              <span className='text-[#3474ba]'>T</span>
              <span className='text-[#e6218e]'>E</span>
              <span>A</span>
              <span>M</span>
              <span>S</span>
            </h2>
            {/* <img src="" alt="" /> */}
          </div>
        </div>

        <div>
          <div className='pt-4 lg:pt-6'>
            <h1 className='newake text-4xl md:text-5xl lg:text-[58px] xl:text-7xl tracking-wide text-center md:text-left'>
              A One Stop Service <br /> Digital Agency
            </h1>
          </div>
          <div className='pt-2'>
            <p className='text-center text-sm md:text-[14px] lg:text-base xl:text-xl md:text-left '>
              Branding | Social Media Management | Design | Website | Digital
              Strategy
            </p>
          </div>
        </div>

        {/* <HomeDecor/> */}
      </div>

      <div className='absolute -z-20 opacity-30 md:opacity-100 md:relative  col-span-4 lg:col-span-5 '>
        <img
          className='md:w-[70%] w-full md:ml-16 h-screen md:h-screen md:object-left object-cover '
          src={Heropicture}
          alt=''
        />
      </div>
    </div>
  );
};

export default Home;
