import Whatsapp from "../../assets/Pictures/CONTACT US/whatsapp.png";
import Instagram from "../../assets/Pictures/CONTACT US/instagram.png";
import Email from "../../assets/Pictures/CONTACT US/email.png";
import ContactUs from "./ContactUs";

import { useRef, useEffect } from "react";
import { useInView, motion, transform } from "framer-motion";

const Footer = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <div className='h-full w-full flex flex-col justify-center bg-[#7839fa] '>
      <div
        ref={ref}
        className='h-full flex flex-col gap-3  xl:gap-20 sm:flex-row  md:justify-around md:items-stretch py-10 px-[10vw] md:w-full '>
        <div className='flex flex-row md:flex md:gap-1 w-full lg:gap-5 gap-2  sm:justify-center  md:justify-between '>
          <div className='hidden md:block row-span-2 my-auto text-left '>
            <h1 className='newake text-white md:text-6xl  whitespace-pre-wrap align'>
              Interested In Our Services{""}
              <span className='newake font-black'>?</span>{" "}
            </h1>
          </div>

          {/* <div className="hidden lg:block col-span-2 row-span-3 xl:col-span-3 ">
            <video autoPlay muted loop
              className='h-full w-full object-cover rounded-xl'
              src={Videofooter}></video>
          </div> */}
          <div className='md:flex md:flex-row md:gap-10 md:justify-center'>
            <div className="flex flex-col lg:flex-row lg:gap-10">
              <motion.div
                className='flex flex-col gap-1 md:gap-2'
                style={
                  {
                    // transform: isInView ? "none" : "translateY(400px)",
                    // opacity: isInView ? 1 : 0,
                    // transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0s",
                  }
                }>
                <h1 className='newake text-3xl  text-white'>CONTACT US</h1>
                <div className='flex flex-col justify-start gap-1 md:gap-1'>
                  <div className='flex flex-row text-sm items-center gap-2'>
                    <img
                      className='w-4 md:w-5 h-full object-contain'
                      src={Whatsapp}
                      alt=''
                    />
                    <p className='  font-normal text-white'>+62 877-9000-3533</p>
                  </div>
                  <div className='flex flex-row items-center gap-2'>
                    <img
                      className='w-4 md:w-5 h-full object-contain'
                      src={Instagram}
                      alt=''
                    />
                    <a href="https://instagram.com/createams.id?igshid=MzRlODBiNWFlZA==" target="_blank" className='e font-normal text-white'>@createams.id</a>
                  </div>
                  <div className='flex flex-row items-center gap-2'>
                    <img
                      className='w-4 md:w-5 h-full object-contain'
                      src={Email}
                      alt=''
                    />
                    <p className='text-base font-normal text-white'>
                      createamsindonesia@gmail.com 
                    </p>
                  </div>
                </div>
              </motion.div>

              {/* <motion.div
                className='flex flex-col gap-1 pt-5 lg:py-0 md:gap-2 sm:pb-4  '
                style={
                  {
                    // transform: isInView ? "none" : "translateY(500px)",
                    // opacity: isInView ? 1 : 0,
                    // transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0s",
                  }
                }>
                <h1 className='newake text-3xl  text-white '>FIND US</h1>
                <div className='flex flex-col justify-start'>
                  <div className='flex flex-row items-center gap-2 w-full md:w-full'>
                    <p className='text-sm font-normal text-justify   text-white w-80'>
                      Jl Lorem ipsum dolor sit amet consectetur adipisicing
                      elit. Ad ut esse repellendus quibusdam dolorum deleniti,
                      maiores odit quae, eaque soluta quam.
                    </p>
                  </div>
                </div>
              </motion.div> */}
            </div>

            {/* <ContactUs inView={isInView} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
