import { useRef, useEffect } from "react";
import { useInView, motion, transform } from "framer-motion";
import projectPattern from "../../assets/Pictures/Pattern/Visiwig-Circle-Overlapped-Alternates.svg"

import ParallaxTextShadow from "./ParallaxTextShadow";

const Whoarewe = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div id="whoweare" className='relative bg-none h-full py-16 md:bg-white w-full flex flex-row items-center justify-center '>

      <div className=' w-full h-full flex flex-col   justify-center items-center px-10 sm:px-10 md:px-[10vw]'>
        <motion.div
          ref={ref}
          className='relative newake  md:w-full w-full pb-4   justify-center items-center flex flex-col text-right md:text-left text-5xl md:text-6xl lg:text-7xl'>
          {/* <h1 className=" w-full">WHO</h1> */}
          <h1 className='w-full text-center tracking-wide'>
            A <span className='text-[#00b73d] '>ONE STOP</span>
            <br className='md:hidden' /> SERVICE
          </h1>

          {/* <ParallaxTextShadow color={"#00b73d"}/> */}
        </motion.div>

        <motion.div
          ref={ref} //
          className='block leading-relaxed lg:leading-loose font-medium w-full lg:w-full md:rounded-md  h-full lg:bg-none insetshadow/ md:border-[#00b73d]'>
          <p className='sm:text-base xl:text-xl  text-center text-sm  lg:w-full lg:text-xl lg:px-36 text-[#000000]'>
            CREATEAMS fokus pada pelayanan one stop service & solusi untuk brand
            kalian. Kami sediakan jasa branding, packaging design, consulting,
            book design, dan market research. Membantu brand marketing menjadi
            lebih mudah.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default Whoarewe;
