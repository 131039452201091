import React from "react";
import ParallaxTextShadow from "./ParallaxTextShadow";

const Title = () => {
  return (
    <div className='relative newake text-5xl md:text-6xl lg:text-7xl w-full text-center '>
      {/* <ParallaxTextShadow /> */}
      <h1 className=' text-black font-extralight tracking-wide'>
        OUR <span className='text-[#0683e4] text-center'>CLIENTS </span>
      </h1>
    </div>
  );
};

export default Title;
