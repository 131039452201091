import React from "react";

const Ourservicecontent = ({title,paragraph,image}) => {
  return (
    <div className=' flex flex-row h-full w-full justify-center  items-center gap-3  lg:text-center sm:gap-5   bg-none  border-[#ffffff]   rounded-tl-2xl '>
      <img className=' h-8 sm:h-14   ' src={image} alt='' />
      <div className='gap-3 flex flex-col'>
        <h2 className=' w-[120px] whitespace-nowrap text-sm sm:text-xl text-[#ffffff] xl:text-2xl font-semibold text-left'>
          {title}
        </h2>
        <p className='text-white text-left text-xs xl:-sm sm:w-96 lg:w-full '>
          {paragraph}
        </p>
      </div>
    </div>
  );
};

export default Ourservicecontent;
