import { useRef } from "react";
import { useInView, motion } from "framer-motion";


import ParallaxTextShadow from "./ParallaxTextShadow";

const Title = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      // style={{
      //   transform: isInView ? "none" : "translateX(200px)",
      //   opacity: isInView ? 1 : 0,
      //   transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0s",
      // }}
      className="relative newake flex flex-col  text-5xl md:text-6xl lg:text-7xl w-full md:gap-5 md:pl-0 text-center tracking-wide"
    > 
    {/* 
    side note: Our and Project file can be remove safely
     */}
      <h1 className="">FEATURED <span className="text-[#7839F9]">PROJECT</span></h1>
      {/* <ParallaxTextShadow/> */}
    </motion.div>
  );
};

export default Title;
