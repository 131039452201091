import ParallaxTextShadow from "./ParallaxTextShadow";

const Title = () => {
  return (
    <div className="relative newake text-5xl md:text-6xl lg:text-7xl w-full text-center  ">
      <h1 className="text-center ">
        OUR <br className="hidden "/><span className="text-[#ef3b58] text-center">SERVICE</span>
      </h1>
      {/* <ParallaxTextShadow/> */}
    </div>
  );
};

export default Title;
