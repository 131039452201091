import DigitalStrategy from "../../assets/Pictures/OUR SERVICE/digital strategy white.png";
import SosmedManagement from "../../assets/Pictures/OUR SERVICE/media production white.png";
import MediaProduction from "../../assets/Pictures/OUR SERVICE/social-media white.png";
import Branding from "../../assets/Pictures/OUR SERVICE/Branding white.png";

import Ourservicecontent from "./Ourservicecontent";

import Title from "./Title";

const Ourservice = () => {
  return (
    <div id="service" className=' h-full  flex flex-col items-center justify-center bg-none gap-9 py-16 px-10 md:px-[10vw]  '>
      <Title />

      <div className='grid grid-cols-1 grid-rows-4 lg:grid lg:grid-cols-2 lg:grid-rows-2 bg-[#ef3b58] font-semibold text-xl rounded-xl tracking-tight text-left w-full md:w-10/12 justify-start items-start lg:justify-center lg:items-center px-[10%] md:px-[5%]  py-10 sm:py-[5%] gap-10 lg:gap-14'>
        <Ourservicecontent title="Digital Strategy" paragraph="Maximize your digital impact with our strategic approach tailored for your success" image={DigitalStrategy}/>
        <Ourservicecontent title="Social Media Management" paragraph="Shape your brand's success with our dynamic digital strategies and visionary thinking" image={SosmedManagement}/>
        <Ourservicecontent title="Branding" paragraph="Transform your brand's digital presence with our captivating storytelling and seamless design" image={Branding}/>
        <Ourservicecontent title="Media Production" paragraph="Elevate your brand with exceptional media production that captivates and inspires." image={MediaProduction}/>
        
        
      </div>
    </div>
  );
};

export default Ourservice;
