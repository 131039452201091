import { motion } from "framer-motion";

const HoverBox = ({ Name, bgvideo }) => {
  let boxVariants = {};
  const isMobile = window.innerWidth < 1024;
  if (!isMobile) {
    boxVariants = {
      statictext: {
        opacity: 0,
        y: -10,
      },
      hover: {
        opacity: 1,
        y: 0,
      },
      staticbox: {
        opacity: 0,
        // x:-10,
      },
    };
  } else {
    boxVariants = {
      statictext: {
        opacity: 1,
      },
      hover: {
        opacity: 1,
      },
      staticbox: {
        opacity: 0,
        // x:-10,
      },
    };
  }
  // console.log(bgvideo);
  return (
    <div>
      <motion.div
        className='w-full h-full absolute z-20  bg-contain bg-no-repeat bg-center newake bg-black bg-opacity-75'
        style={{ backgroundImage: `url(${bgvideo})` }}
        variants={boxVariants}
        initial='staticbox'
        whileHover='hover'>
        {/* <motion.h1
          className='text-xl hidden lg:block w-full h-full pt-10 text-center lg:text-3xl  thinblackstroke text-white drop-shadow-lg '
          variants={boxVariants}
          initial='statictext'
          whileHover='hover'
          >
          {Name}
        </motion.h1> */}
      </motion.div>
    
    </div>
  );
};

export default HoverBox;
