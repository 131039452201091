import React from "react";
import { motion } from "framer-motion";
const text = "Createams".split("");
const colors = [
  "#8145f6",
  "#188ae0",
  "#f3b92a",
  "#f93669",
  "#5fb84e",
  "#8145f6",
  "#188ae0",
  "#f3b92a",
  "#f93669",
];

const Title = () => {
  return (
    <motion.div className="z-50">
      {text.map((letter, index) => {
        return (
          <motion.span
            className={`text-5xl introTitleColor`}
            initial={{opacity:0}}
            animate={{opacity:1}}
            transition={{duration:0.8}}
            key={index}
          >
            {letter}
          </motion.span>
        );
      })}
    </motion.div>
  );
};

export default Title;
