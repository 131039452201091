import React, { useEffect, useState } from "react";
import Logo from "../assets/Pictures/LOGO/logo createams-01.webp";
import CreateamsLogoDesign from "../assets/Pictures/HOMEPAGE/HOME-PAGE-01.webp";
import { AiOutlineMenu } from "react-icons/ai";
import { motion, useScroll, AnimatePresence } from "framer-motion";

const Navbar = ({ color }) => {
  const [navshow, setNavshow] = useState(-360);
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
    if (open === false) {
      setNavshow(-355);
    } else if (open === true) {
      setNavshow(0);
    }
  }

  window.onscroll = function (e) {
    // print "false" if direction is down and "true" if up
    if (this.oldScroll > this.scrollY) {
      setNavshow(-355);
    } else {
      if (open === false) {
        setNavshow(-445);
      } else if (open === true) {
        setNavshow(-550);
      }
    }
    this.oldScroll = this.scrollY;
  };

  return (
    <motion.div layout>
      <motion.nav
        className='fixed w-full h-auto  flex flex-col border-gray-200 px-4 z-50 sm:px-10 py-2.5 bg-[#ffffff] bg-opacity-50 dark:bg-gray-900 border-b-2'
        animate={{
          // backgroundColor: "#ffffff",
          borderBottomColor: color,
          top: navshow,
        }}>
        <div className='pb-10 pt-4 flex flex-col gap-4'>
          <a href='#whoweare'>
            <div className='h-16 w-full rounded-md bg-[#00b73d] hover:bg-[#15be4d] active:bg-[#21e462] newake flex text-4xl justify-center items-center border-[2px] border-wh text-white pt-2'>
              Who We Are
            </div>
          </a>
          <a href='#service'>
            <div className='h-16 w-full rounded-md bg-[#ef3b58] hover:bg-[#ed5972] active:bg-[#ff5874] newake flex border-[2px] border-white text-4xl justify-center items-center text-white pt-2'>
              Our Service
            </div>
          </a>
          <a href='#project'>
            <div className='h-16 w-full rounded-md bg-[#7839F9] hover:bg-[#864bfb] active:bg-[#925bff] newake flex border-[2px] border-white text-4xl justify-center items-center text-white pt-2'>
              Featured Project
            </div>
          </a>
          <a href='#client'>
            <div className='h-16 w-full rounded-md bg-[#0683e4] hover:bg-[#1d92ec] active:bg-[#2a9ef7] newake flex border-[2px] border-white text-4xl justify-center items-center text-white pt-2'>
              Our Client
            </div>
          </a>
        </div>
        <motion.div className=' flex flex-row flex-wrap h-full items-center  md:px-0 w-full relative justify-between overflow-hidden'>
          {/* <a href='#home' className=' flex items-center'> */}
          <img
            src={Logo}
            className='h-9 mr-3 sm:h-12'
            alt='Logo'
          />
          {/* <img
            src={CreateamsLogoDesign}
            className='h-auto  sm:h-96 absolute left-0 right-0 mx-auto my-0'
            alt='Logo'
          /> */}
          {/* </a> */}
          <div className='text-2xl text-blue cursor-pointer h-full justify-center items-center'>
            <button onClick={handleClick}>
              <AiOutlineMenu />
            </button>
          </div>
        </motion.div>
      </motion.nav>
    </motion.div>
  );
};

export default Navbar;
