import LandingPage from "./components/LandingPage";

function App() {
  return (
    <div className="w-full h-full "> 
      
      <LandingPage /> 
    </div>
  );
}

export default App;
