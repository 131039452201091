import Home from "./landingPage/Home/Home";
import Navbar from "./landingPage/Navbar";
import Whoarewe from "./landingPage/WhoWeAre/Whoarewe";
import Ourproject from "./landingPage/Projects/Ourproject";
import Ourservice from "./landingPage/Service/Ourservice";
import Whychooseus from "./landingPage/ChooseUs/Whychooseus";
import Ourclients from "./landingPage/Client/Ourclients";
import Footer from "./landingPage/Footer/Footer";
import Intro from "./Intro/OpeningIntro";

import { useRef, useEffect, useState } from "react";
import { motion, useInView } from "framer-motion";

const LogoNames = [
  "Home",
  "About Us",
  "Our Project",
  "Our Service",
  "Why Us",
  "Our Client",
  "Contact",
];

const LandingPage = () => {
  const HomeRef = useRef(null);
  const WhoRef = useRef(null);
  const ProjectRef = useRef(null);
  const ServiceRef = useRef(null);
  const ChooseRef = useRef(null);
  const ClientRef = useRef(null);

  const HomeView = useInView(HomeRef, { amount: 0.5 });
  const WhoView = useInView(WhoRef, { amount: 0.5 });
  const ProjectView = useInView(ProjectRef, { amount: 0.5 });
  const ServiceView = useInView(ServiceRef, { amount: 0.5 });
  const ChooseView = useInView(ChooseRef, { amount: 0.5 });
  const ClientView = useInView(ClientRef, { amount: 0.5 });

  // const WhoView = useInView(WhoRef, {amount: 0.4});

  const [navcolor, setnavcolor] = useState("#ffffff");

  useEffect(() => {
    if (HomeView) setnavcolor("#ffffff");
    else if (WhoView) setnavcolor("#00b73d");
    else if (ServiceView) setnavcolor("#ef3b58");
    else if (ProjectView) setnavcolor("#7839F9");
    // else if (ChooseView) setnavcolor("#fbc800");
    else if (ClientView) setnavcolor("#0683e4");
    // else setnavcolor("#ffffff");
  }, [HomeView, WhoView, ProjectView, ServiceView, ChooseView, ClientView]);

  // useEffect(() => {
  //   console.log("Element is in view: ", WhoView,ProjectView, navcolor);
  // }, [HomeView,ProjectView]);

  return (
    <motion.div className='relative'>
      <Navbar color={navcolor} />
      {/* <Intro /> */}
      <div className='h-full overflow-hidden bg-opacity-10'>
        <div ref={HomeRef}>
          <Home id='home' />
        </div>

        <div ref={WhoRef}>
          <Whoarewe id='aboutus' />
        </div>
        {/* <hr className='border-[1px] border-gray-500 mx-10 md:mx-[10vw] lg:hidden' /> */}
        <div ref={ServiceRef}>
          <Ourservice id='ourservice' />
        </div>
        {/* <hr className='border-[1px] border-gray-500 mx-10 md:mx-[10vw] lg:hidden' /> */}

        <div ref={ProjectRef}>
          <Ourproject id='ourproject' />
        </div>

        {/* <div ref={ChooseRef}> */}
        {/* <Whychooseus /> */}
        {/* </div> */}

        {/* <hr className='border-[1px] border-gray-500 mx-10 md:mx-[10vw] lg:hidden' /> */}

        <div ref={ClientRef}>
          <Ourclients />
        </div>
        <div>
          <Footer id='footer' />
        </div>
      </div>
    </motion.div>
  );
};

export default LandingPage;
